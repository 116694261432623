// Adjust this value to match your actual navbar height

.shop {
  display: flex;
  flex-direction: column;
  /* Instead of min-height: 100vh, use min-height: 100% so it fits within App-content */
  min-height: 100%;
  
  /* When a product detail page is open, the product grid may blur */
  &.pdp-open .product-grid {
    filter: blur(10px);
    transform: scale(0.95);
  }

  .product-grid {
    transition: filter 0.4s ease, transform 0.4s ease;
  }

  &.transitioning .product-grid {
    pointer-events: none;
  }
}

.shop-header {
  background-color: #f8f8f8;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h1 {
    margin: 0;
    font-size: 2em;
    color: #333;
  }

  nav {
    margin-top: 20px;

    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      gap: 20px;

      li a {
        text-decoration: none;
        color: #555;
        font-weight: bold;
        transition: color 0.3s ease;

        &:hover {
          color: #ff69b4;
        }

        &.active {
          color: #ff69b4;
          text-decoration: underline;
        }
      }
    }
  }
}

.shop-main {
  flex: 1;
  padding: 40px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }

  h3 {
    margin: 15px 0 10px;
    font-size: 1.2em;
    color: #333;
  }

  p {
    color: #ff69b4;
    font-weight: bold;
    margin-bottom: 15px;
  }

  button {
    background-color: #ff69b4;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}

/* ============================= */
/*        PRODUCT DETAIL         */
/* ============================= */

.product-detail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  z-index: 1000;

  .close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
    z-index: 1001;

    &:hover {
      color: #ff69b4;
    }
  }

  .pdp-container {
  display: flex;
  width: 100%;
  height: 100vh; /* The entire viewport height */
  overflow: hidden; /* Hide any overflow from the container itself */
}

/* Left side: Images panel */
.pdp-images {
  position: relative;
  width: 50vw;       /* Takes half the screen width (adjust as needed) */
  height: 100vh;     /* Full viewport height */
  overflow-y: scroll; /* Scroll for images only */

  /* Hide scrollbar visually (optional) */
  scrollbar-width: none; 
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  img {
    display: block;
    width: 100%;
    height: 100vh; /* Each image takes a full screen’s height */
    object-fit: cover; /* Crop/cover or contain to suit your design */
  }
}

/* Right side: Product info panel */
.pdp-info {
  position: relative;
  width: 50vw;      /* Other half of the screen */
  height: 100vh;    /* Full viewport height */
  overflow-y: scroll;

  /* Similarly hide scrollbar if desired */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  padding: 40px;
  box-sizing: border-box;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    /* styling... */
  }
}

  .product-content {
    display: flex;
    height: 100%;
  }

  .product-images {
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .product-info {
    flex: 1;
    padding: 40px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px; 
    &::-webkit-scrollbar {
      display: none;
    }
    

    h2 {
      font-size: 2em;
      margin-bottom: 10px;
    }

    .price {
      font-size: 1.4em;
      color: #ff69b4;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .category {
      font-style: italic;
      color: #666;
      margin-bottom: 20px;
    }

    .add-to-cart {
      background-color: #ff69b4;
      color: #fff;
      border: none;
      padding: 15px 30px;
      font-size: 1.1em;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 100%;
      max-width: 300px;

      &:hover {
        background-color: #ff69b47e;
      }
    }

    .product-details,
    .delivery-returns {
      margin-bottom: 30px;
      width: 100%;
      max-width: 400px;

      h3 {
        font-size: 1.2em;
        margin-bottom: 10px;
      }

      p {
        line-height: 1.6;
      }
    }

    .product-footer {
      margin-top: 50px;
      text-align: center;
      color: #666;
      width: 100%;
    }
  }
}

.product-details ol, 
.product-info ol {
  list-style-type: decimal; /* or whatever you prefer */
  margin-left: 2em;         /* some indentation */
  padding-left: 1em;        /* padding for spacing */
}

.product-details ol li,
.product-info ol li {
  margin-bottom: 0.5em;     /* spacing between items */
  line-height: 1.4;
}

/* ============================= */
/*        QUICK BUY MODAL        */
/* ============================= */

.quick-buy-modal {
  position: fixed;
  inset: 0; /* top:0; bottom:0; left:0; right:0; */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; 
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
    position: relative; /* for the close button */

    .modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.8em;
      cursor: pointer;
      color: #333;
      transition: color 0.3s ease;

      &:hover {
        color: #ff69b4;
      }
    }

    h2 {
      margin-bottom: 10px;
      font-size: 1.5em;
    }

    p {
      color: #ff69b4;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .modal-images {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      margin-bottom: 20px;

      img {
        max-width: 100px;
        border-radius: 4px;
      }
    }

    .variant-selector {
      margin-bottom: 20px;

      label {
        font-weight: bold;
        margin-right: 10px;
      }

      select {
        padding: 6px;
        border-radius: 4px;
        border: 1px solid #ccc;
        outline: none;
      }
    }

    .add-to-cart {
      background-color: #ff69b4;
      color: #fff;
      border: none;
      padding: 12px 20px;
      font-size: 1em;
      cursor: pointer;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #ff69b4;
      }
    }
  }
}

/* ============================= */
/*      RESPONSIVE STYLES        */
/* ============================= */

// Remove the keyframe animation
@keyframes growIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .product-detail .pdp-container {
    flex-direction: column;
  }

  .product-detail .pdp-images,
  .product-detail .product-info {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .product-grid {
    grid-template-columns: 1fr;
  }

  .product-detail .pdp-container {
    flex-direction: column;
  }

  .product-detail .pdp-images,
  .product-detail .product-info {
    flex: none;
    width: 100%;
    max-height: 50vh;
  }

  .product-detail .product-info {
    padding: 10px 0px;

    h2 {
      font-size: 1.5em;
    }

    .price {
      font-size: 1.2em;
    }

    .add-to-cart {
      padding: 10px 20px;
      font-size: 1em;
    }

    .product-details,
    .delivery-returns {
      h3 {
        font-size: 1em;
      }

      p {
        font-size: 0.9em;
      }
    }
  }

  .product-detail .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5em;
  }
}
