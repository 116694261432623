.recordings {
  text-align: center;
}

.recordings-list ul {
  list-style-type: none;
  padding: 0;
}

.recordings-list li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.recordings-list li:hover {
  background-color: #f0f0f0;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.upcoming-sets {
  flex: 1;
  overflow: hidden;
  margin-bottom: 40px;

  h2 {
    margin-bottom: 20px;
  }

  .set-list {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 20px;
  }

  .set-card {
    flex: 0 0 auto;
    width: 250px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 16px rgba(0,0,0,0.15);
      cursor: pointer;
    }

    .set-thumbnail {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    .set-info {
      padding: 15px;

      h3 {
        margin: 0 0 10px;
        font-size: 1.2rem;
      }

      p {
        margin: 5px 0;
        font-size: 0.9rem;
        color: #666;
      }
    }
  }
}

.radio-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
  }

  h2 {
    font-size: 1.8rem;
    color: #444;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
}

.upcoming-sets {
  flex: 1;
  overflow: hidden;
  margin-bottom: 40px;

  .set-list {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 20px;
  }

}

.recordings-list {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;

}

