.Nav {
  position: sticky;
  top: 0;
  z-index: 100;
}

#mainLogo {
  height: 100%;
  user-select: none;
}

#navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  background-color: #0B0B0B;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: 80px; /* Reduced height for a sleeker topbar */
}

#navLinks {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem; /* Spacing between links */

  a {
    color: #eaeaea;
    text-decoration: none;
    position: relative;
    font-weight: 500;
    font-size: 0.95rem;
    transition: color 0.3s ease;

    /* Modern underline hover effect */
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -3px; /* Slight space under the text */
      width: 0;
      height: 1px;
      background-color: #eaeaea;
      transition: width 0.3s ease;
    }

    &:hover {
      color: #ffffff;
    }

    &:hover::after {
      width: 100%;
    }
  }
}

/* Hide the menu toggle on desktop */
.menu-toggle {
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  #navContainer {
    flex-direction: row;
    height: auto;
    padding: 10px 20px;
    position: relative;
  }

  #mainLogo {
    width: 90px;
    user-select: none;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    width: 10px;
    height: 10px;
    position: relative;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #eaeaea;
      position: absolute;
      left: 0;
      transition: all 0.3s ease;

      &:nth-child(1) { top: 0; }
      &:nth-child(2) { top: 50%; transform: translateY(-50%); }
      &:nth-child(3) { bottom: 0; }
    }

    &.open {
      span {
        &:nth-child(1) { transform: translateY(9px) rotate(45deg); }
        &:nth-child(2) { opacity: 0; }
        &:nth-child(3) { transform: translateY(-9px) rotate(-45deg); }
      }
    }
  }

  #navLinks {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #111; /* Darker background for a uniform look */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, padding 0.5s ease;

    &.open {
      max-height: 300px;
      padding: 10px 0;
    }

    a {
      margin: 10px 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      opacity: 0;
      transform: translateY(-10px);
      transition: opacity 0.3s ease, transform 0.3s ease;
      color: #eaeaea;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05); 
      }

      /* Staggered animation */
      &:nth-child(1) { transition-delay: 0.1s; }
      &:nth-child(2) { transition-delay: 0.2s; }
      &:nth-child(3) { transition-delay: 0.3s; }
      &:nth-child(4) { transition-delay: 0.4s; }
      &:nth-child(5) { transition-delay: 0.5s; }
    }

    &.open a {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
