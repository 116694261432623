.listContainer {
  background: #111;
  min-height: 100%;  /* Instead of 100vh, so it fills its parent container */
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  padding: 2rem;
  /* If you previously had max-width or margins that centered content, remove them or adjust as needed */
}
.pageTitle {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.artistList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
/* Default state: all rows fully visible */
.artistRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  color: #fff;
  transition: background 0.3s ease, opacity 0.3s ease;
  background: #1a1a1a;
  opacity: 1;
  min-height: 125px; /* Add this line to ensure a minimum height */
}

/* When hovering over the artistList, 
   all non-hovered rows fade out */
.artistList:hover .artistRow:not(:hover) {
  opacity: 0.4;
}

/* The hovered row stays full opacity and a bit darker */
.artistRow:hover {
  background: #333;
  opacity: 1;
  cursor: pointer;
}

.artistInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.artistName {
  font-size: 1.5rem;
  margin: 0;
  color: #fff;
}

.socialLinks a {
  margin-right: 1rem;
  color: #bbb;
  font-size: 0.9rem;
  text-decoration: none;
}

.socialLinks a:hover {
  text-decoration: underline;
  color: #fff;
}

.selectedDate {
  font-size: 0.9rem;
  color: #aaa;
}

/* Detail page styles remain the same */
.detailContainer {
  background: #111;
  color: #eaeaea;
  min-height: 100vh;
  padding: 2rem;
}

.backLink {
  color: #bbb;
  text-decoration: none;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  display: inline-block;
}

.backLink:hover {
  text-decoration: underline;
  color: #fff;
}

.artistDetail {
  max-width: 800px;
  margin: 0 auto;
}

.detailName {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #fff;
}

.detailBio {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: #eaeaea;
}

.detailSocials a {
  margin-right: 1rem;
  color: #bbb;
  font-size: 1rem;
  text-decoration: none;
}

.detailSocials a:hover {
  color: #fff;
}

.videoSectionTitle {
  margin: 2rem 0 1rem;
  font-size: 1.5rem;
  color: #fff;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


.socialLinks a,
.detailSocials a,
.backLink,
.artistLink {
  color: #bbb;
  text-decoration: none;
}

.socialLinks a:visited,
.detailSocials a:visited,
.backLink:visited,
.artistLink:visited {
  color: #bbb; /* same color as unvisited links */
  text-decoration: none;
}

/* On hover, change color if desired */
.socialLinks a:hover,
.detailSocials a:hover,
.backLink:hover,
.artistLink:hover {
  color: #fff;
  text-decoration: none; /* or none if you prefer */
}

.socialLinks a:active,
.detailSocials a:active,
.backLink:active,
.artistLink:active {
  color: #fff;
  text-decoration: none;
}
