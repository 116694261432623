html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Reserve room for the fixed footer */
.App-content {
  flex: 1;
  overflow: auto;
  margin-bottom: 60px; /* Adjust this value to match your footer's height + padding */
}

/* Fixed footer styling */
footer, .shop-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
  z-index: 100;
}

body {
  font-family: 'Montserrat', sans-serif;
}

body.pdp-open {
  overflow: hidden !important;
}

.shop-footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

*::-webkit-scrollbar-thumb:active {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.5);
}

