/* Reset defaults (if not already applied globally) */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.home-content {
  position: relative; /* For absolute children */
  overflow: hidden;   /* Hide any accidental overflow */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content vertically */
  width: 100%;        /* Use 100% of parent's width */
  height: 100%;      /* Fill the viewport height */
  font-family: "Montserrat", sans-serif;
}

/* The canvas background covers the entire home-content */
.wave-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

/* Other HomeContent rules remain unchanged */
.home-text-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: -2;
  }
}

.home-text-main {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  user-select: none;
  h1 {
    font-size: 40px;
    margin: 0;
  }
  
  .animated-typewriter {
    font-size: 40px;
    margin: 10px 0 20px 0;
  }
  
  p {
    font-size: 25px;
    width: 50%;
    line-height: 1.5;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    margin: 0;
  }
}

.center-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  width: 40vw;
  height: auto;
  filter: brightness(0.1);
  user-select: none;
}

/* Responsive adjustments (optional) */
@media (max-width: 768px) {
  .home-text-main {
    font-size: 30px;
  }
  
  .home-text-main h1,
  .animated-typewriter {
    font-size: 30px;
  }
  
  .animated-typewriter {
    margin: 10px 0 15px 0;
  }
  
  .home-text-main p {
    font-size: 20px;
    width: 80%;
  }
  
  .center-image {
    width: 60vw;
  }
}
