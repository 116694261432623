@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.aboutContainer {
  display: flex;
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
}

.aboutContent {
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  height: 83vh;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.scrollContent {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px; // Adds space between direct child elements
}

// Apply animation and basic styles to text elements
.aboutContent h1,
.aboutContent h2,
.aboutContent h3,
.aboutContent p {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
  width: 100%;
  margin: 0; // Reset default margins
}

// Specific styles for different text elements
.aboutContent h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.aboutContent h2 {
  font-size: 2em;
  margin-bottom: 15px;
}

.aboutContent h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.aboutContent p {
  font-size: 1em;
  line-height: 1.6;
}

// Staggered animation for different text elements
.aboutContent h1 {
  animation-delay: 0.2s;
}
.aboutContent h2 {
  animation-delay: 0.4s;
}
.aboutContent h3 {
  animation-delay: 0.6s;
}
.aboutContent p {
  animation-delay: 0.8s;
}

// Optional: Style the scrollbar
.aboutContent::-webkit-scrollbar {
  width: 8px;
}

.aboutContent::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.aboutContent::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.aboutContent::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .aboutContent {
    padding: 20px;
  }

  .aboutContent h1 {
    font-size: 2em;
  }

  .aboutContent h2 {
    font-size: 1.5em;
  }

  .aboutContent h3 {
    font-size: 1.2em;
  }

  .aboutContent p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .aboutContent {
    padding: 15px;
  }

  .aboutContent h1 {
    font-size: 1.8em;
  }

  .aboutContent h2 {
    font-size: 1.3em;
  }

  .aboutContent h3 {
    font-size: 1.1em;
  }

  .aboutContent p {
    font-size: 0.8em;
  }
}
