.playlists-page {
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.playlist-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.playlist-page {
  padding: 20px;

  h1 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  .track-list {
    list-style-type: none;
    padding: 0;

    li {
      padding: 10px 0;
      border-bottom: 1px solid #eee;
    }
  }

  .back-link {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    color: #007bff;

    &:hover {
      text-decoration: underline;
    }
  }
}

.playlist-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;

  &:hover {
    transform: translateY(-5px);
  }

  .playlist-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .playlist-info {
    padding: 15px;

    h3 {
      margin: 0 0 10px;
    }

    p {
      margin: 0;
      font-size: 0.9em;
      color: #666;
    }
  }
}



@media (max-width: 768px) {
  .playlist-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .playlist-grid {
    grid-template-columns: 1fr;
  }

  .playlist-card .playlist-image {
    height: 150px;
  }
}