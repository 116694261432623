@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

.soundcloud-player {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  transition: all 0.3s ease;
  font-family: "Roboto", sans-serif;

  &.expanded {
    width: 300px;
    height: 250px; // Further reduced from 300px
  }

  .player-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2); // Light, semi-transparent background
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      pointer-events: none;
    }
  }

  &.expanded .player-container {
    opacity: 1;
    transform: translateY(0);
  }

  .custom-player {
    padding: 12px; // Further reduced from 15px
    color: #333;
    height: 96%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: filter 0.3s ease;

    &.blurred {
      filter: blur(5px);
    }
    &.loading {
      filter: blur(9px);
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  
    &.visible {
      opacity: 1;
    }
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #ff69b4;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .track-info {
    text-align: center;
    margin-bottom: 10px; // Reduced from 15px

    h3 {
      margin: 0;
      font-size: 10px; // Reduced from 18px
      color: #ff69b4;
      text-shadow: 0 0 5px rgba(255, 105, 180, 0.3);
      opacity: 0.8;
    }

    p {
      margin: 3px 0 0; // Reduced top margin
      font-size: 12px; // Reduced from 14px
      color: #ffffff;
      opacity: 0.6;
    }
  }

  .progress-container {
    margin-bottom: 8px; // Reduced from 10px

    .progress-bar {
      width: 100%;
      -webkit-appearance: none;
      background: transparent;
      margin-bottom: 3px; // Reduced from 5px

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px; // Reduced from 4px
        background: rgba(255, 105, 180, 0.3);
        border-radius: 2px;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 12px; // Reduced from 16px
        width: 12px; // Reduced from 16px
        border-radius: 50%;
        background: #ff69b4;
        margin-top: -6px; // Adjusted for new height
        box-shadow: 0 0 5px rgba(255, 105, 180, 0.5);
        animation: slide-thumb linear;
        animation-play-state: paused;
      }
    }
    @keyframes slide-thumb {
      from {
        left: 0%;
      }
      to {
        left: 100%;
      }
    }
    .time-display {
      display: flex;
      justify-content: space-between;
      font-size: 10px; // Reduced from 12px
      color: #ffffff;
      opacity: 0.6;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background: none;
      border: none;
      font-size: 20px; // Reduced from 24px
      cursor: pointer;
      margin: 0 8px; // Reduced from 10px
      color: #ff69b4;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .cooldown-indicator {
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: 10px;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #ccc;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: #ff69b4;
        animation: cooldown-spin 0.5s linear;
        opacity: 0;
      }

      &.ready::before {
        opacity: 1;
        animation: none;
        border-color: #ff69b4;
      }
    }
  }

  .volume-control {
    margin-bottom: 10px; // Reduced from 15px

    input[type="range"] {
      width: 100%;
      -webkit-appearance: none;
      background: transparent;

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px; // Reduced from 4px
        background: rgba(255, 105, 180, 0.3);
        border-radius: 2px;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 12px; // Reduced from 16px
        width: 12px; // Reduced from 16px
        border-radius: 50%;
        background: #ff69b4;
        margin-top: -4.5px; // Adjusted for new height
        box-shadow: 0 0 5px rgba(255, 105, 180, 0.5);
      }
    }
    .waveform-container {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      margin-top: 8px;
      background: rgba(255, 105, 180, 0.1);
      border-radius: 5px;
    }

    .waveform-bar {
      flex: 1;
      background: #ff69b4;
      margin: 0 1px;
      transition: height 0.1s ease;
    }
  }

  .tracklist-button {
    background: none;
    border: none;
    color: #ff69b4;
    cursor: pointer;
    font-size: 20px; // Reduced from 24px
    padding: 5px 5px 20px 5px; // Reduced from 8px
    transition: all 0.3s ease;
    align-self: flex-end;

    &:hover {
      transform: scale(1.1);
    }
  }

  .tracklist-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1); // Very light background
    backdrop-filter: blur(5px); // This creates the frosted glass effect
    -webkit-backdrop-filter: blur(10px); // For Safari support
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
    z-index: 10;
    border-radius: 10px; // Matching the player's border radius
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    border: 1px solid rgba(255, 255, 255, 0.3); // Subtle border for definition

    h3 {
      color: #ff69b4;
      margin-bottom: 15px;
      font-weight: 600;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 5px;
        color: #333; // Darker text for better readability

        &:hover {
          background-color: rgba(255, 105, 180, 0.2);
        }
      }
    }

    .close-tracklist {
      background: none;
      border: none;
      color: #ff69b4;
      cursor: pointer;
      font-size: 14px;
      margin-top: 20px;
      align-self: center;
      transition: all 0.3s ease;
      padding: 8px 16px;
      border-radius: 20px;
      border: 1px solid #ff69b4;

      &:hover {
        background-color: rgba(255, 105, 180, 0.1);
        transform: scale(1.05);
      }
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #ff69b4;
    opacity: 0.7;
    transition: all 0.3s ease;
    z-index: 5;

    &:hover {
      opacity: 1;
    }
  }

  .button-container {
    position: relative;
  }

  .hover-text {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 105, 180, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    white-space: nowrap;
    pointer-events: none;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  &:hover .hover-text {
    opacity: 1;
    visibility: visible;
    right: calc(100% + 10px);
  }

  .expand-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 105, 180, 0.8);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(255, 105, 180, 0.3);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 4px 15px rgba(255, 105, 180, 0.5);
    }

    img {
      width: 30px;
      height: 30px;
    }

    &.playing {
      animation: pulse 2s infinite;
      
      &::after {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border-radius: 50%;
        border: 2px solid rgba(255, 105, 180, 0.5);
        animation: ripple 2s linear infinite;
      }
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 105, 180, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 105, 180, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 105, 180, 0);
    }
  }
  
  @keyframes ripple {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  .track-list {
    margin-top: 10px;

    select {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid rgba(255, 105, 180, 0.3);
      background-color: rgba(255, 255, 255, 0.1);
      color: #333;
      font-size: 14px;
      outline: none;
      transition: all 0.3s ease;

      &:focus {
        border-color: #ff69b4;
        box-shadow: 0 0 5px rgba(255, 105, 180, 0.5);
      }

      option {
        background-color: white;
      }
    }
  }

  .playlist-toggle {
    position: absolute;
    right: 15px;
    background: none;
    border: none;
    color: #ff69b4;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}

@keyframes cooldown-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.soundcloud-player {
  .progress-bar,
  .volume-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: rgba(255, 105, 180, 0.3);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #ff69b4;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
    }

    &::-moz-range-thumb {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #ff69b4;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
    }

    &:hover::-webkit-slider-thumb {
      box-shadow: 0 0 10px #ff69b4;
    }

    &:hover::-moz-range-thumb {
      box-shadow: 0 0 10px #ff69b4;
    }
  }
}




@media (max-width: 768px) {
  .soundcloud-player {
    &.expanded {
      width: 250px;
      height: 200px;
    }
  }
}

@media (max-width: 480px) {
  .soundcloud-player {
    bottom: 10px;
    right: 10px;

    &.expanded {
      width: 200px;
      height: 180px;
    }

    .expand-button {
      width: 40px;
      height: 40px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}